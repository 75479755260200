// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/src/assets/images/HTC_logo_Black_1.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.backdrop-section-container {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-bottom: 100px;
}

.backdrop-logo {
    max-width: 200px;
    margin-top: 130px;
    margin-bottom: 20px;
    z-index: 1;
}

.logo-under-ground,
.logo-under-ground1 {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: contain;
    background-repeat: repeat;
    background-position: center;
    z-index: 0;
    transform: rotate(-8.3deg);
    position: absolute;
    width: 200%;
    top: 0;
    left: 0;
    height: 500px;
    opacity: .2;
    filter: blur(4px);
}

.logo-under-ground1 {
    top: 650px !important;
}

.backdrop-banner {
    max-width: 100%;
    width: 100%;
    height: auto;
    position: relative;
    z-index: 1;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/Home/HomeOrganisms/BackdropSection/BackdropSection.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,sBAAsB;IACtB,mBAAmB;IACnB,qBAAqB;AACzB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,UAAU;AACd;;AAEA;;IAEI,yDAA8D;IAC9D,wBAAwB;IACxB,yBAAyB;IACzB,2BAA2B;IAC3B,UAAU;IACV,0BAA0B;IAC1B,kBAAkB;IAClB,WAAW;IACX,MAAM;IACN,OAAO;IACP,aAAa;IACb,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,eAAe;IACf,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,UAAU;AACd","sourcesContent":[".backdrop-section-container {\n    position: relative;\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n    align-items: center;\n    padding-bottom: 100px;\n}\n\n.backdrop-logo {\n    max-width: 200px;\n    margin-top: 130px;\n    margin-bottom: 20px;\n    z-index: 1;\n}\n\n.logo-under-ground,\n.logo-under-ground1 {\n    background-image: url(/src/assets/images/HTC_logo_Black_1.png);\n    background-size: contain;\n    background-repeat: repeat;\n    background-position: center;\n    z-index: 0;\n    transform: rotate(-8.3deg);\n    position: absolute;\n    width: 200%;\n    top: 0;\n    left: 0;\n    height: 500px;\n    opacity: .2;\n    filter: blur(4px);\n}\n\n.logo-under-ground1 {\n    top: 650px !important;\n}\n\n.backdrop-banner {\n    max-width: 100%;\n    width: 100%;\n    height: auto;\n    position: relative;\n    z-index: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
