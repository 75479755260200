// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gallery-action-container {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 100px;
    flex-direction: column;
    margin-bottom: 100px;
}

.gallery-action-container .nav-tabs {
    border-bottom: none;
    justify-content: center;
}

.gallery-action-container .nav-item {
    margin: 0 10px !important;
}


.gallery-action-container .nav-tabs .nav-link:hover {
    border: none !important;
}

.gallery-action-container .nav-tabs .nav-link {
    font-size: 1.5rem;
    line-height: 1.55;
    text-decoration: none;
    font-weight: 700;
    cursor: pointer;
    color: var(--white-color);
    border: none
}

.gallery-action-container .nav-tabs .nav-link.active {
    border: none;
    background-color: transparent;
    color: var(--red-color);

}`, "",{"version":3,"sources":["webpack://./src/components/pages/Gallery/GalleryOrganisms/GalleryAction/GalleryAction.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,kBAAkB;IAClB,sBAAsB;IACtB,oBAAoB;AACxB;;AAEA;IACI,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,yBAAyB;AAC7B;;;AAGA;IACI,uBAAuB;AAC3B;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;IACjB,qBAAqB;IACrB,gBAAgB;IAChB,eAAe;IACf,yBAAyB;IACzB;AACJ;;AAEA;IACI,YAAY;IACZ,6BAA6B;IAC7B,uBAAuB;;AAE3B","sourcesContent":[".gallery-action-container {\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    padding-top: 100px;\n    flex-direction: column;\n    margin-bottom: 100px;\n}\n\n.gallery-action-container .nav-tabs {\n    border-bottom: none;\n    justify-content: center;\n}\n\n.gallery-action-container .nav-item {\n    margin: 0 10px !important;\n}\n\n\n.gallery-action-container .nav-tabs .nav-link:hover {\n    border: none !important;\n}\n\n.gallery-action-container .nav-tabs .nav-link {\n    font-size: 1.5rem;\n    line-height: 1.55;\n    text-decoration: none;\n    font-weight: 700;\n    cursor: pointer;\n    color: var(--white-color);\n    border: none\n}\n\n.gallery-action-container .nav-tabs .nav-link.active {\n    border: none;\n    background-color: transparent;\n    color: var(--red-color);\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
