// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gallery-banner-container {
    position: relative;
}

.gallery-banner {
    width: 100%;
    height: auto;
    display: block;
    object-fit: cover;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/Gallery/GalleryOrganisms/GalleryBanner/GalleryBanner.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,cAAc;IACd,iBAAiB;AACrB","sourcesContent":[".gallery-banner-container {\n    position: relative;\n}\n\n.gallery-banner {\n    width: 100%;\n    height: auto;\n    display: block;\n    object-fit: cover;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
