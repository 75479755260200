// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-container {
    width: 100%;
    position: relative;
    padding: 16px;
}

.gallery-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
}

.gallery-flex {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: space-between;
}

.gallery-item {
    flex: 0 0 auto;
    width: auto;
    height: 200px;
    overflow: hidden;
    border-radius: 8px;
}

.gallery-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease-in-out;
}

.gallery-item:hover img {
    transform: scale(1.05);
}

.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: transparent;
    color: white;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    font-size: 18px;
    cursor: pointer;
    display: none;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: opacity 0.3s ease, transform 0.3s ease;
    border: 1px solid white;
}

.scroll-to-top.show {
    display: flex;
    opacity: 1;
}

.scroll-to-top:hover {
    transform: scale(1.1);
}`, "",{"version":3,"sources":["webpack://./src/components/atoms/ScrollTop/ScrollTop.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,SAAS;IACT,8BAA8B;AAClC;;AAEA;IACI,cAAc;IACd,WAAW;IACX,aAAa;IACb,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,sCAAsC;AAC1C;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,eAAe;IACf,YAAY;IACZ,WAAW;IACX,6BAA6B;IAC7B,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,eAAe;IACf,eAAe;IACf,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,wCAAwC;IACxC,kDAAkD;IAClD,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,UAAU;AACd;;AAEA;IACI,qBAAqB;AACzB","sourcesContent":[".main-container {\n    width: 100%;\n    position: relative;\n    padding: 16px;\n}\n\n.gallery-container {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding: 16px;\n}\n\n.gallery-flex {\n    display: flex;\n    flex-wrap: wrap;\n    gap: 16px;\n    justify-content: space-between;\n}\n\n.gallery-item {\n    flex: 0 0 auto;\n    width: auto;\n    height: 200px;\n    overflow: hidden;\n    border-radius: 8px;\n}\n\n.gallery-item img {\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n    transition: transform 0.3s ease-in-out;\n}\n\n.gallery-item:hover img {\n    transform: scale(1.05);\n}\n\n.scroll-to-top {\n    position: fixed;\n    bottom: 20px;\n    right: 20px;\n    background-color: transparent;\n    color: white;\n    border: none;\n    border-radius: 50%;\n    width: 50px;\n    height: 50px;\n    font-size: 18px;\n    cursor: pointer;\n    display: none;\n    justify-content: center;\n    align-items: center;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);\n    transition: opacity 0.3s ease, transform 0.3s ease;\n    border: 1px solid white;\n}\n\n.scroll-to-top.show {\n    display: flex;\n    opacity: 1;\n}\n\n.scroll-to-top:hover {\n    transform: scale(1.1);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
