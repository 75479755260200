// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#progress-container {
    position: fixed;
    top: 0;
    left: 45px;
    width: 73px;
    height: 100vh;
    background: transparent;
    padding-top: 48px;
    border-left: 1px solid #ffffff4a;
    border-right: 1px solid #ffffff4a;
    z-index: 995;
}

#progress-bar {
    width: 100%;
    height: 0;
    background: #ffffff;
    transition: height 0.2s ease-out;
    max-height: calc(100vh - 48px);
}

#progress-container .logo-black {
    position: absolute;
    top: 70px;
}

@media (max-width: 768px) {
    #progress-container {
        display: none;
    }

    #progress-bar {
        display: none;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/atoms/ScrollProgressBar/ScrollProgressBar.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,UAAU;IACV,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,iBAAiB;IACjB,gCAAgC;IAChC,iCAAiC;IACjC,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,SAAS;IACT,mBAAmB;IACnB,gCAAgC;IAChC,8BAA8B;AAClC;;AAEA;IACI,kBAAkB;IAClB,SAAS;AACb;;AAEA;IACI;QACI,aAAa;IACjB;;IAEA;QACI,aAAa;IACjB;AACJ","sourcesContent":["#progress-container {\n    position: fixed;\n    top: 0;\n    left: 45px;\n    width: 73px;\n    height: 100vh;\n    background: transparent;\n    padding-top: 48px;\n    border-left: 1px solid #ffffff4a;\n    border-right: 1px solid #ffffff4a;\n    z-index: 995;\n}\n\n#progress-bar {\n    width: 100%;\n    height: 0;\n    background: #ffffff;\n    transition: height 0.2s ease-out;\n    max-height: calc(100vh - 48px);\n}\n\n#progress-container .logo-black {\n    position: absolute;\n    top: 70px;\n}\n\n@media (max-width: 768px) {\n    #progress-container {\n        display: none;\n    }\n\n    #progress-bar {\n        display: none;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
