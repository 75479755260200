// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gallery-container {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 16px;
    flex-direction: column;
    align-items: center;
}

.gallery-flex {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
}

.gallery-item {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: calc(33.333% - 16px);
    max-width: 300px;
    min-width: 200px;
    overflow: hidden;
    border-radius: 8px;
    height: 300px;
    position: relative;
    cursor: pointer;
}

.gallery-item img,
.gallery-item video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease-in-out;
}

.gallery-item:hover img,
.gallery-item:hover video {
    transform: scale(1.05);
}

.loading {
    margin: 32px 0;
    font-size: 20px;
    color: #555;
    text-align: center;
}

.observer-trigger {
    height: 1px;
    width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/Gallery/GalleryOrganisms/GalleryList/GalleryList.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,SAAS;IACT,uBAAuB;IACvB,uBAAuB;IACvB,WAAW;AACf;;AAEA;IACI,YAA8B;IAA9B,cAA8B;IAA9B,gCAA8B;IAC9B,gBAAgB;IAChB,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;IAClB,aAAa;IACb,kBAAkB;IAClB,eAAe;AACnB;;AAEA;;IAEI,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,sCAAsC;AAC1C;;AAEA;;IAEI,sBAAsB;AAC1B;;AAEA;IACI,cAAc;IACd,eAAe;IACf,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,WAAW;AACf","sourcesContent":[".gallery-container {\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    padding: 16px;\n    flex-direction: column;\n    align-items: center;\n}\n\n.gallery-flex {\n    display: flex;\n    flex-wrap: wrap;\n    gap: 16px;\n    justify-content: center;\n    align-items: flex-start;\n    width: 100%;\n}\n\n.gallery-item {\n    flex: 1 1 calc(33.333% - 16px);\n    max-width: 300px;\n    min-width: 200px;\n    overflow: hidden;\n    border-radius: 8px;\n    height: 300px;\n    position: relative;\n    cursor: pointer;\n}\n\n.gallery-item img,\n.gallery-item video {\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n    transition: transform 0.3s ease-in-out;\n}\n\n.gallery-item:hover img,\n.gallery-item:hover video {\n    transform: scale(1.05);\n}\n\n.loading {\n    margin: 32px 0;\n    font-size: 20px;\n    color: #555;\n    text-align: center;\n}\n\n.observer-trigger {\n    height: 1px;\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
