// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-team-container {
    display: flex;
    justify-content: center;
    padding: 50px;
}

.home-team {
    max-width: 1440px;
    width: 100%;
}

.team-title-container {
    width: 100%;
}

.team-title-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    padding-bottom: 20px;
}

.team-title {
    text-transform: uppercase;
    line-height: 1.2;
    font-weight: 900;
    letter-spacing: -.05em;
    font-size: 32px;
}

.team-sub-title {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 13px;
    line-height: 1.2;
    letter-spacing: -.03em;
}

@media (min-width: 768px) {
    .team-title {
        font-size: 56px;
    }

    .team-sub-title {
        font-weight: 700;
        font-size: 16px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/pages/Home/HomeOrganisms/HomeTeam/HomeTeam.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,aAAa;AACjB;;AAEA;IACI,iBAAiB;IACjB,WAAW;AACf;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,2BAA2B;IAC3B,SAAS;IACT,oBAAoB;AACxB;;AAEA;IACI,yBAAyB;IACzB,gBAAgB;IAChB,gBAAgB;IAChB,sBAAsB;IACtB,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB,gBAAgB;IAChB,eAAe;IACf,gBAAgB;IAChB,sBAAsB;AAC1B;;AAEA;IACI;QACI,eAAe;IACnB;;IAEA;QACI,gBAAgB;QAChB,eAAe;IACnB;AACJ","sourcesContent":[".home-team-container {\n    display: flex;\n    justify-content: center;\n    padding: 50px;\n}\n\n.home-team {\n    max-width: 1440px;\n    width: 100%;\n}\n\n.team-title-container {\n    width: 100%;\n}\n\n.team-title-container {\n    display: flex;\n    align-items: center;\n    justify-content: flex-start;\n    gap: 10px;\n    padding-bottom: 20px;\n}\n\n.team-title {\n    text-transform: uppercase;\n    line-height: 1.2;\n    font-weight: 900;\n    letter-spacing: -.05em;\n    font-size: 32px;\n}\n\n.team-sub-title {\n    text-transform: uppercase;\n    font-weight: 700;\n    font-size: 13px;\n    line-height: 1.2;\n    letter-spacing: -.03em;\n}\n\n@media (min-width: 768px) {\n    .team-title {\n        font-size: 56px;\n    }\n\n    .team-sub-title {\n        font-weight: 700;\n        font-size: 16px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
