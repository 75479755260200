// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-container {
    width: 100%;
    font-family: "Space Grotesk", serif;
}

.home-content-container {
    padding: 0 20px;
}

@media (min-width: 1200px) {
    .home-content-container {
        padding: 0 50px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/Home/HomePage.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,mCAAmC;AACvC;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI;QACI,eAAe;IACnB;AACJ","sourcesContent":[".home-container {\n    width: 100%;\n    font-family: \"Space Grotesk\", serif;\n}\n\n.home-content-container {\n    padding: 0 20px;\n}\n\n@media (min-width: 1200px) {\n    .home-content-container {\n        padding: 0 50px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
