// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-base-container .modal-content {
    background-color: var(--background-color);
    box-shadow: 0 4px 20px rgba(255, 255, 255, 0.2),
        0 4px 8px rgba(0, 0, 0, 0.2);
}
`, "",{"version":3,"sources":["webpack://./src/components/atoms/Modal/Modal.css"],"names":[],"mappings":"AAAA;IACI,yCAAyC;IACzC;oCACgC;AACpC","sourcesContent":[".modal-base-container .modal-content {\n    background-color: var(--background-color);\n    box-shadow: 0 4px 20px rgba(255, 255, 255, 0.2),\n        0 4px 8px rgba(0, 0, 0, 0.2);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
