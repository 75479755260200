// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.not-found-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: var(--background-color);
    font-family: Arial, sans-serif;
    opacity: .8;
}

.not-found-content {
    text-align: center;
    padding: 20px;
    border: 1px solid #d1d1d1;
    border-radius: 8px;
    background-color: var(--background-color);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.not-found-title {
    font-size: 6rem;
    color: var(--red-color);
    font-weight: bold;
}

.not-found-message {
    font-size: 1.2rem;
    color: white;
    margin: 20px 0;
}

.not-found-link {
    display: inline-block;
    padding: 10px 20px;
    font-size: 1.1rem;
    text-decoration: none;
    background-color: var(--red-color);
    color: white;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.not-found-link:hover {
    background-color: var(--red-color);
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/NotFound/NotFound.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,yCAAyC;IACzC,8BAA8B;IAC9B,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,yBAAyB;IACzB,kBAAkB;IAClB,yCAAyC;IACzC,wCAAwC;AAC5C;;AAEA;IACI,eAAe;IACf,uBAAuB;IACvB,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;IACjB,YAAY;IACZ,cAAc;AAClB;;AAEA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,iBAAiB;IACjB,qBAAqB;IACrB,kCAAkC;IAClC,YAAY;IACZ,kBAAkB;IAClB,sCAAsC;AAC1C;;AAEA;IACI,kCAAkC;AACtC","sourcesContent":[".not-found-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n    background-color: var(--background-color);\n    font-family: Arial, sans-serif;\n    opacity: .8;\n}\n\n.not-found-content {\n    text-align: center;\n    padding: 20px;\n    border: 1px solid #d1d1d1;\n    border-radius: 8px;\n    background-color: var(--background-color);\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n}\n\n.not-found-title {\n    font-size: 6rem;\n    color: var(--red-color);\n    font-weight: bold;\n}\n\n.not-found-message {\n    font-size: 1.2rem;\n    color: white;\n    margin: 20px 0;\n}\n\n.not-found-link {\n    display: inline-block;\n    padding: 10px 20px;\n    font-size: 1.1rem;\n    text-decoration: none;\n    background-color: var(--red-color);\n    color: white;\n    border-radius: 4px;\n    transition: background-color 0.3s ease;\n}\n\n.not-found-link:hover {\n    background-color: var(--red-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
