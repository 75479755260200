// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-layout {
    min-height: 100vh;
    width: 100vw;
    background-color: var(--background-color);
    color: white;
    padding-left: 120px;
}

@media (max-width: 768px) {
    .main-layout {
        padding: 0 5px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/layouts/MainLayout/MainLayout.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,YAAY;IACZ,yCAAyC;IACzC,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI;QACI,cAAc;IAClB;AACJ","sourcesContent":[".main-layout {\n    min-height: 100vh;\n    width: 100vw;\n    background-color: var(--background-color);\n    color: white;\n    padding-left: 120px;\n}\n\n@media (max-width: 768px) {\n    .main-layout {\n        padding: 0 5px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
