// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gallery-template {
    display: flex;
    flex-direction: column;
}

.artist-preview-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.artist-preview-container img {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    margin-right: 8px;
}

.artist-preview-name {
    color: var(--white-color);
    font-weight: 500;
}

.artist-view-tweet:hover {
    opacity: .8;
}

.modal-base-container:has(.modal-preview-container) .modal-header {
    border: none !important;
}

.artist-view-tweet {
    color: rgb(70, 161, 220);
    text-decoration-color: initial;
    font-size: 15px;
    font-family: "Chakra Petch", sans-serif;
    font-weight: 500;
    cursor: pointer;
    font-style: normal;
}

.artist-dot {
    color: white;
    margin: 0 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/Gallery/GalleryTemplate/GalleryTemplate.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,2BAA2B;IAC3B,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,wBAAwB;IACxB,8BAA8B;IAC9B,eAAe;IACf,uCAAuC;IACvC,gBAAgB;IAChB,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,cAAc;AAClB","sourcesContent":[".gallery-template {\n    display: flex;\n    flex-direction: column;\n}\n\n.artist-preview-container {\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n}\n\n.artist-preview-container img {\n    width: 40px;\n    height: 40px;\n    border-radius: 100%;\n    margin-right: 8px;\n}\n\n.artist-preview-name {\n    color: var(--white-color);\n    font-weight: 500;\n}\n\n.artist-view-tweet:hover {\n    opacity: .8;\n}\n\n.modal-base-container:has(.modal-preview-container) .modal-header {\n    border: none !important;\n}\n\n.artist-view-tweet {\n    color: rgb(70, 161, 220);\n    text-decoration-color: initial;\n    font-size: 15px;\n    font-family: \"Chakra Petch\", sans-serif;\n    font-weight: 500;\n    cursor: pointer;\n    font-style: normal;\n}\n\n.artist-dot {\n    color: white;\n    margin: 0 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
